// frontend/src/CreateMemberProfilePage.js
import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import "./CreateMemberProfilePage.css"; // Reuse the same styling, or create a similar CSS file

export default function CreateMemberProfilePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [netid, setNetid] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [affiliation, setAffiliation] = useState("");

  const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

  useEffect(() => {
    // Grab ?netid= from the URL if present
    const queryNetid = searchParams.get("netid") || "";
    setNetid(queryNetid);
  }, [searchParams]);

  async function handleSave() {
    // Basic validation
    if (!firstName.trim() || !lastName.trim()) {
      alert("First Name and Last Name are required.");
      return;
    }

    const payload = {
      netid,
      first_name: firstName,
      last_name: lastName,
      affiliation,
    };

    try {
      const res = await fetch(`${API_BASE_URL}/members`, {
        credentials: "include",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!res.ok) {
        throw new Error("Error creating member");
      }

      const data = await res.json();
      alert("Member profile created successfully!");
      navigate(`/members/${data.member.netid}`);
    } catch (error) {
      console.error("Error saving member profile:", error);
      alert("Error saving member profile");
    }
  }

  return (
    <div className="profile-container">
      <h1 className="profile-title">Member Profile</h1>
      <p className="netid-text">{netid ? `${netid}@princeton.edu` : ""}</p>

      {/* Single centered card for inputs */}
      <div className="profile-cards">
        <div className="profile-card">
          <div className="card-header">Profile Details</div>
          <div className="card-body">
            <div className="form-group">
              <label>First Name *</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label>Last Name *</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label>Affiliations (e.g. clubs, sports, etc.)</label>
              <textarea
                rows={2}
                value={affiliation}
                onChange={(e) => setAffiliation(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="profile-actions">
        <button className="save-button" onClick={handleSave}>
          Save Member Profile
        </button>
      </div>
    </div>
  );
}
