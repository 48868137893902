import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./MemberProfilePage.css"; // We'll define some styling

const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

function MemberProfilePage() {
  const { netid } = useParams();
  const [memberData, setMemberData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  // For editing a card
  const [showModal, setShowModal] = useState(false);
  const [editingCard, setEditingCard] = useState(null);
  const [modalComment, setModalComment] = useState("");

  useEffect(() => {
    async function fetchMemberData() {
      try {
        const response = await fetch(`${API_BASE_URL}/members/${netid}/stats`, {
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setMemberData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }
    fetchMemberData();
  }, [netid]);

  // Helper: refresh member data
  async function refreshData() {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(`${API_BASE_URL}/members/${netid}/stats`, {
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setMemberData(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }

  // If loading or error
  if (loading) return <div className="member-profile-container">Loading...</div>;
  if (error) return <div className="member-profile-container">Error: {error}</div>;
  if (!memberData) return <div className="member-profile-container">No data found.</div>;

  const { first_name, last_name, affiliation, cards } = memberData;
  const {
    green_cards = [],
    red_cards = [],
    crane_cards = [],
    last_word_cards = [],
    non_affiliation_cards = [],
  } = cards || {};

  // Single-card type arrays => we only show the first if it exists
  const craneCard = crane_cards[0] || null;
  const lastWordCard = last_word_cards[0] || null;
  const nonAffCard = non_affiliation_cards[0] || null;

  // ----------------------------------------------------------
  // Handle editing a card
  // ----------------------------------------------------------
  function handleEditCard(card) {
    // card = { id, text, bickeree_name, bickeree_netid }
    setEditingCard(card);
    setModalComment(card.text); // prefill with the current text
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
    setEditingCard(null);
    setModalComment("");
  }

  // PUT request to update the card
  async function saveCard() {
    if (!modalComment.trim()) {
      alert("Comment cannot be empty.");
      return;
    }
    try {
      // e.g. PUT /bickerees/:bickeree_netid/cards/:card_id
      const url = `${API_BASE_URL}/bickerees/${editingCard.bickeree_netid}/cards/${editingCard.id}`;
      const res = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ comment: modalComment }),
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.detail || "Failed to update card.");
      }
      // success
      closeModal();
      refreshData();
    } catch (err) {
      alert(err.message);
    }
  }

  // DELETE request for the card
  async function deleteCard() {
    if (!window.confirm("Are you sure you want to delete this card?")) return;
    try {
      const url = `${API_BASE_URL}/bickerees/${editingCard.bickeree_netid}/cards/${editingCard.id}`;
      const res = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.detail || "Failed to delete card.");
      }
      // success
      closeModal();
      refreshData();
    } catch (err) {
      alert(err.message);
    }
  }

  // A small helper to render each card
  function renderCardItem(card, colorClass) {
    return (
      <div key={card.id} className={`card-item ${colorClass}`}>
        <p>{card.text}</p>
        {/* Now show which bickeree we wrote it for */}
        {card.bickeree_name && (
          <div className="card-meta">For: {card.bickeree_name}</div>
        )}
        {/* Pencil icon for editing */}
        <span
          className="edit-icon"
          onClick={() => handleEditCard(card)}
          title="Edit this card"
        >
          &#9998;
        </span>
      </div>
    );
  }

  return (
    <div className="member-profile-container">
      <div className="member-header">
        <h1>
          {first_name} {last_name}
        </h1>
        <p className="affiliation-text">
          {affiliation ? affiliation : "No affiliation provided"}
        </p>
      </div>

      {/* Top row: Green + Red cards */}
      <div className="cards-row">
        {/* Green Cards Column */}
        <div className="cards-column">
          <div className="cards-column-header">
            <h2>Green Cards - {green_cards.length}</h2>
          </div>
          <div className="cards-list green-bg">
            {green_cards.length === 0 && (
              <div className="empty-state">No green cards yet</div>
            )}
            {green_cards.map((card) => renderCardItem(card, "green-card"))}
          </div>
        </div>

        {/* Red Cards Column */}
        <div className="cards-column">
          <div className="cards-column-header">
            <h2>Red Cards - {red_cards.length}</h2>
          </div>
          <div className="cards-list red-bg">
            {red_cards.length === 0 && (
              <div className="empty-state">No red cards yet</div>
            )}
            {red_cards.map((card) => renderCardItem(card, "red-card"))}
          </div>
        </div>
      </div>

      {/* Bottom row: Non-Aff, Crane, Last Word */}
      <div className="bottom-row">
        {/* Non-Affiliation */}
        <div className="single-card-box">
          <h3>Non-Affiliation</h3>
          <div className="single-card-content">
            {nonAffCard ? (
              <>
                <p>{nonAffCard.text}</p>
                <div className="card-meta">
                  For: {nonAffCard.bickeree_name}
                  <span
                    className="edit-icon"
                    onClick={() => handleEditCard(nonAffCard)}
                    title="Edit this card"
                  >
                    &#9998;
                  </span>
                </div>
              </>
            ) : (
              <div className="empty-state">No non-affiliation card</div>
            )}
          </div>
        </div>

        {/* Crane Card */}
        <div className="single-card-box">
          <h3>Crane Card</h3>
          <div className="single-card-content">
            {craneCard ? (
              <>
                <p>{craneCard.text}</p>
                <div className="card-meta">
                  For: {craneCard.bickeree_name}
                  <span
                    className="edit-icon"
                    onClick={() => handleEditCard(craneCard)}
                    title="Edit this card"
                  >
                    &#9998;
                  </span>
                </div>
              </>
            ) : (
              <div className="empty-state">No crane card</div>
            )}
          </div>
        </div>

        {/* Last Word */}
        <div className="single-card-box">
          <h3>Last Word</h3>
          <div className="single-card-content">
            {lastWordCard ? (
              <>
                <p>{lastWordCard.text}</p>
                <div className="card-meta">
                  For: {lastWordCard.bickeree_name}
                  <span
                    className="edit-icon"
                    onClick={() => handleEditCard(lastWordCard)}
                    title="Edit this card"
                  >
                    &#9998;
                  </span>
                </div>
              </>
            ) : (
              <div className="empty-state">No last word card</div>
            )}
          </div>
        </div>
      </div>

      {/* Modal for Editing/Deleting */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Edit Your Card</h2>
            <textarea
              rows={5}
              value={modalComment}
              onChange={(e) => setModalComment(e.target.value)}
              style={{ width: "100%" }}
            />
            <div className="modal-actions">
              <button className="modal-delete-btn" onClick={deleteCard}>
                Delete
              </button>
              <button className="modal-cancel-btn" onClick={closeModal}>
                Cancel
              </button>
              <button className="modal-save-btn" onClick={saveCard}>
                Save
              </button>
            </div>
          </div>
        </div>
        )}
    </div>
  );
}

export default MemberProfilePage;
