import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaHome, FaUser, FaSync } from "react-icons/fa"; // Import FaSync for refresh icon
import "./NavBar.css";

const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

function NavBar() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [userNetid, setUserNetid] = useState(null);

  // Tracks whether we're currently fetching bickerees
  const [loadingBickerees, setLoadingBickerees] = useState(false);

  // We'll store the bickerees list locally for quick client-side filtering
  const [localBickerees, setLocalBickerees] = useState([]);

  const navigate = useNavigate();

  // ------------------------------------
  // On mount:
  //  1) read localStorage for netid
  //  2) check if we should refetch by time-based or if "allBickerees" doesn't exist
  //  3) else read them into localBickerees
  // ------------------------------------
  useEffect(() => {
    const storedNetid = localStorage.getItem("netid");
    if (storedNetid) {
      setUserNetid(storedNetid);
    }

    const lastFetchedStr = localStorage.getItem("bickereesLastFetched");
    const now = Date.now();
    let shouldRefetch = false;
  
    if (!lastFetchedStr) {
      // never fetched
      shouldRefetch = true;
    } else {
      const lastFetched = parseInt(lastFetchedStr);
      // 2 hours in ms => 7,200,000
      if ((now - lastFetched) > 7_200_000) {
        // older than 2 hours => re-fetch
        shouldRefetch = true;
      }
    }
  
    if (shouldRefetch) {
      fetchAllBickereesOnce();
    } else {
      // use the local data
      const storedBickerees = localStorage.getItem("allBickerees");
      if (storedBickerees) {
        setLocalBickerees(JSON.parse(storedBickerees));
      }
    }
  }, []);

  // ------------------------------------
  // Utility to fetch the bickeree list, store in localStorage & state
  // ------------------------------------
  async function fetchAllBickereesOnce() {
    setLoadingBickerees(true);
    try {
      const resp = await fetch(`${API_BASE_URL}/bickerees/all`, {
        credentials: "include",
      });
      if (!resp.ok) {
        throw new Error("Failed to fetch bickerees list.");
      }
      const data = await resp.json();
      // Sort them by first_name
      data.sort((a, b) =>
        a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
      );
      // store in localStorage
      localStorage.setItem("allBickerees", JSON.stringify(data));
      localStorage.setItem("bickereesLastFetched", Date.now().toString());
      setLocalBickerees(data);
    } catch (err) {
      console.error("Error fetching all bickerees:", err);
    } finally {
      setLoadingBickerees(false);
    }
  }

  // ------------------------------------
  // Searching by name => local filter
  // ------------------------------------
  useEffect(() => {
    if (loadingBickerees || !localBickerees.length) {
      // If bickerees aren't loaded yet => no results
      setSearchResults([]);
      return;
    }
    if (!searchTerm.trim()) {
      setSearchResults([]);
      return;
    }

    // local filter:
    const term = searchTerm.toLowerCase();
    const filtered = localBickerees.filter((b) => {
      const fullName = (b.first_name + " " + b.last_name).toLowerCase();
      return (
        b.first_name.toLowerCase().includes(term) ||
        b.last_name.toLowerCase().includes(term) ||
        fullName.includes(term)
      );
    });

    setSearchResults(filtered);
  }, [searchTerm, localBickerees, loadingBickerees]);

  // When user clicks a search result, go to that Bickeree
  const handleResultClick = (netid) => {
    setSearchTerm("");
    setSearchResults([]);
    navigate(`/bickerees/${netid}`);
  };

  // ------------------------------------
  // "Home" button => first bickeree in localStorage
  // ------------------------------------
  function handleHomeClick(e) {
    e.preventDefault();
    if (loadingBickerees) return;

    if (localBickerees.length > 0) {
      navigate(`/bickerees/${localBickerees[0].netid}`);
    } else {
      alert("No bickerees found in local storage.");
    }
  }

  // Manual refresh button => re-fetch bickerees
  function handleRefreshClick() {
    if (loadingBickerees) return;
    fetchAllBickereesOnce();
  }

  // If loadingBickerees => disable links + show "loading..." text
  const homeDisabled = loadingBickerees;
  const profileDisabled = loadingBickerees;

  return (
    <div className="navBarContainer">
      {/* Left side: "Home" icon, "Profile" icon */}
      <div className="navLeft">
        {/* HOME icon => handleHomeClick if not loading */}
        <a
          href="#home"
          onClick={handleHomeClick}
          className={`iconLink ${homeDisabled ? "disabledLink" : ""}`}
        >
          {loadingBickerees ? (
            <span style={{ fontSize: "14px", color: "#fff" }}>
              Loading bickerees...
            </span>
          ) : (
            <FaHome size={24} />
          )}
        </a>

        {/* PROFILE icon => goes to /members/[netid] if not loading */}
        <a
          href={profileDisabled ? "#profile" : userNetid ? `/members/${userNetid}` : "/signin"}
          onClick={(e) => {
            if (profileDisabled) {
              e.preventDefault();
              alert("Still loading bickerees...");
            }
          }}
          className={`iconLink ${profileDisabled ? "disabledLink" : ""}`}
        >
          <FaUser size={24} />
        </a>
      </div>

      {/* Center title */}
      <div className="navCenter">
        <h1>Cannon Bicker</h1>
      </div>

      {/* Right side => search + refresh icon */}
      <div className="navRight" style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <div className="searchContainer">
          <input
            type="text"
            placeholder="Search bickerees by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="searchInput"
            disabled={loadingBickerees} // disable search if still loading
          />
          {searchResults.length > 0 && searchTerm.trim() && (
            <ul className="searchDropdown">
              {searchResults.map((bickeree) => (
                <li
                  key={bickeree.netid}
                  onClick={() => handleResultClick(bickeree.netid)}
                  className="searchDropdownItem"
                >
                  {bickeree.first_name} {bickeree.last_name}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Small refresh button/icon, disabled if loading */}
        <button
          className="refreshButton"
          onClick={handleRefreshClick}
          disabled={loadingBickerees}
          title="Refresh bickeree list"
          style={{
            backgroundColor: "#ffffff",
            border: "1px solid #ccc",
            borderRadius: "4px",
            cursor: loadingBickerees ? "not-allowed" : "pointer",
            padding: "5px 8px",
            fontSize: "14px",
          }}
        >
          {/* If you prefer an icon, e.g. <FaSync /> */}
          <FaSync size={16} style={{ verticalAlign: "middle" }} />
        </button>
      </div>
    </div>
  );
}

export default NavBar;
