import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./CreateBickereeProfilePage.css";

const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export default function CreateBickereeProfilePage() {
  const navigate = useNavigate();

  // State for the form fields
  const [netid, setNetid] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [classYear, setClassYear] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [photo, setPhoto] = useState("");
  const [isNew, setIsNew] = useState(true);

  // Modal state
  const [modal, setModal] = useState({ isOpen: false, message: "" });

  useEffect(() => {
    checkBickereeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkBickereeStatus() {
    try {
      const resp = await fetch(`${API_BASE_URL}/bickerees/self`, {
        credentials: "include",
      });
      if (resp.ok) {
        setIsNew(false);
        const data = await resp.json();
        setNetid(data.netid || "");
        setFirstName(data.first_name || "");
        setLastName(data.last_name || "");
        setClassYear(data.class_year || "");
        setAffiliation(data.affiliation || "");
        setPhoto(data.photo || "");
      } else if (resp.status === 404) {
        setIsNew(true);
        const sessionResp = await fetch(`${API_BASE_URL}/check-session`, {
          credentials: "include",
        });
        if (sessionResp.ok) {
          const sessionData = await sessionResp.json();
          setNetid(sessionData.netid || "");
        } else {
          // For now, still use a normal alert if user not logged in:
          alert("Not logged in or session invalid");
        }
      } else {
        console.error(`Error checking bickeree. Status: ${resp.status}`);
      }
    } catch (error) {
      console.error("Error fetching bickeree:", error);
    }
  }

  const handlePhotoChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      setPhoto(base64String);
    };
    reader.readAsDataURL(file);
  };

  async function handleSave() {
    // Basic validations
    if (!firstName.trim() || !lastName.trim() || !photo.trim()) {
      // We can also show an error modal if you wish, for now let's keep alert for missing fields:
      alert("First Name, Last Name, and Photo are required.");
      return;
    }

    const payload = {
      netid,
      first_name: firstName,
      last_name: lastName,
      class_year: classYear ? parseInt(classYear, 10) : null,
      affiliation,
      photo,
    };

    try {
      let url = `${API_BASE_URL}/bickerees`;
      let method = "POST";
      if (!isNew) {
        url = `${API_BASE_URL}/bickerees/${netid}`;
        method = "PUT";
      }

      const res = await fetch(url, {
        method,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!res.ok) {
        throw new Error(`Error ${isNew ? "creating" : "updating"} bickeree`);
      }

      // Instead of alert, show the modal
      const successMessage = isNew
        ? "Thank you for creating a CDE Bicker profile!"
        : "Your profile has been updated!";
      setModal({
        isOpen: true,
        message: successMessage,
      });

      // Optionally do something else, e.g. navigate("/some-other-page");
    } catch (err) {
      console.error(err);
      alert(`Error ${isNew ? "creating" : "updating"} bickeree`);
    }
  }

  // Dismiss the modal
  function closeModal() {
    setModal({ isOpen: false, message: "" });
  }

  return (
    <div className="profile-container">
      <h1 className="profile-title">Your Profile</h1>
      <p className="netid-text">{netid}@princeton.edu</p>

      <div className="profile-cards">
        {/* LEFT CARD: Profile Picture */}
        <div className="profile-card">
          <div className="card-header">Profile Picture</div>
          <div className="card-body">
            <div className="avatar-container">
              {photo ? (
                <img
                  className="profile-avatar"
                  src={`data:image/jpeg;base64,${photo}`}
                  alt="Profile"
                />
              ) : (
                <div className="empty-avatar">No Photo</div>
              )}
            </div>
            <div className="upload-wrapper">
              <label htmlFor="fileInput" className="upload-button">
                Upload
              </label>
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handlePhotoChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>

        {/* RIGHT CARD: Account Details */}
        <div className="profile-card">
          <div className="card-header">Profile Details</div>
          <div className="card-body">
            <div className="form-group">
              <label>First Name *</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Last Name *</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Class Year</label>
              <input
                type="number"
                value={classYear}
                onChange={(e) => setClassYear(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Affiliations (e.g. clubs, sports, etc.)</label>
              <textarea
                rows={2}
                value={affiliation}
                onChange={(e) => setAffiliation(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="profile-actions">
        <button className="save-button" onClick={handleSave}>
          {isNew ? "Save Profile" : "Save Changes"}
        </button>
      </div>

      {/* Our custom modal (only render if isOpen is true) */}
      {modal.isOpen && (
        <div className="custom-modal-overlay">
          <div className="custom-modal-content">
            <p>{modal.message}</p>
            <button className="modal-close-button" onClick={closeModal}>
              Dismiss
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
