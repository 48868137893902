import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";

const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

function Home() {
  const navigate = useNavigate();
  const [sessionData, setSessionData] = useState(null);

  // Fetch session info on component mount
  useEffect(() => {
    const checkSession = async () => {
      try {
        const sessionResp = await fetch(`${API_BASE_URL}/check-session`, {
          credentials: "include",
        });

        if (sessionResp.ok) {
          const data = await sessionResp.json();
          // data = { netid: "jdoe", role: "bickeree", etc. }
          setSessionData(data);
        } else {
          // If no valid session
          setSessionData(null);
        }
      } catch (error) {
        console.error("Error checking session:", error);
        setSessionData(null);
      }
    };

    checkSession();
  }, []);

  async function checkMemberProfile() {
    try {
      const resp = await fetch(`${API_BASE_URL}/members/self`, {
        credentials: "include",
      });
  
      if (resp.ok) {
        // The member already exists → go to stats page
        navigate(`/members/${sessionData.netid}`);
      } else if (resp.status === 404) {
        // No member record → go to member creation
        navigate(`/create-member?netid=${sessionData.netid}`);
      } else {
        // Some other error → handle gracefully or redirect
        alert("Error checking member profile");
      }
    } catch (error) {
      console.error("Error checking member profile:", error);
      alert("Could not check member profile");
    }
  }
  

  const handleButtonClick = () => {
    // If not logged in → go to sign-in
    if (!sessionData) {
      navigate("/signin");
      return;
    }

    // If logged in → route based on role
    switch (sessionData.role) {
      case "bickeree":
        navigate(`/create-bickeree?netid=${sessionData.netid}`);
        break;
    
      case "member":
        checkMemberProfile();
        break;
    
      default:
        navigate("/signin");
        break;
    }
  };

  return (
    <div className="home-container">
      <div className="home-overlay">
        <h1 className="club-title">CANNON DIAL ELM CLUB</h1>

        {/* Conditional button label */}
        <button onClick={handleButtonClick} className="get-started-button">
          {sessionData ? "Get Started" : "Sign In"}
        </button>
      </div>
    </div>
  );
}

export default Home;
