// frontend/src/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";

// Create the context
const AuthContext = createContext(null);
const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

/**
 * Provide the Auth state and a function to re-check or update session data.
 * `user` will be something like { netid: "jdoe", role: "member" },
 * or null if not logged in.
 * `loading` indicates we're still checking the session from the backend.
 */
export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);   // store session data
  const [loading, setLoading] = useState(true);

  // On mount, attempt to fetch session info from the backend
  useEffect(() => {
    async function checkSession() {
      try {
        const resp = await fetch(`${API_BASE_URL}/check-session`, {
          credentials: "include", // so cookies are included
        });
        if (resp.ok) {
          const data = await resp.json();
          // For example, data = { netid: "jdoe", role: "member" }
          setUser(data);
        } else {
          // If 401 or any error => user not logged in
          setUser(null);
        }
      } catch (err) {
        console.error("Session check error:", err);
        setUser(null);
      } finally {
        setLoading(false);
      }
    }

    checkSession();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
}

// custom hook to access this AuthContext
export function useAuth() {
  return useContext(AuthContext);
}
