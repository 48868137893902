import React from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import "./SignInPage.css"; // Import the styling

function SignInPage() {
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

  const handleSuccess = async (credentialResponse) => {
    try {
      const idToken = credentialResponse.credential;
      const res = await fetch(`${API_BASE_URL}/login/google`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id_token: idToken }),
      });
  
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.detail || "Login failed");
      }
  
      const data = await res.json();
      console.log(data.netid);
  
      // Check session again
      const sessionResp = await fetch(`${API_BASE_URL}/check-session`, {
        credentials: "include",
      });
  
      if (sessionResp.ok) {
        const userData = await sessionResp.json();
        localStorage.setItem("netid", data.netid)
        if (data.role === "member") {
          const memberFetch = await fetch(`${API_BASE_URL}/members/self`, {
            credentials: "include",
          });
          if (memberFetch.ok) {
            window.location.href = `/`; // Navigate to member page
            return;
          } else {
            window.location.href = `/`; // Navigate to create-member page
            return;
          }
        } else {
          window.location.href = `/`; // Navigate to create-bickeree page
          return;
        }
      }
    } catch (error) {
      console.error("Google login error:", error);
      alert("Failed to sign in with Google");
    }
  };
  

  const handleFailure = (error) => {
    console.error("Google login failed:", error);
    alert("Google Sign-In failed");
  };

  return (
    <div className="signin-container">
      <div className="signin-overlay">
        <h1>Sign In With Google</h1>
        {/* The GoogleLogin component renders its own button; 
            you can style it by adding a custom class if needed: */}
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleFailure}
          // Optional: your own custom style
          // useOneTap or a custom className to style further
        />
      </div>
    </div>
  );
}

export default SignInPage;
