// frontend/src/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

function ProtectedRoute({ allowedRoles = [], children }) {
  const { user, loading } = useAuth();

  if (loading) {
    // If session check is still loading, you can return a spinner or placeholder
    return <div>Loading session info...</div>;
  }

  if (!user) {
    // Not logged in => push to sign in
    return <Navigate to="/signin" replace />;
  }

  // If roles are restricted and user.role is not in that array => redirect home (or 403 page).
  if (allowedRoles.length && !allowedRoles.includes(user.role)) {
    return <Navigate to="/" replace />;
  }

  // Otherwise, render the actual page
  return children;
}

export default ProtectedRoute;
