// frontend/src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";

import NavBar from "./NavBar";
import Home from "./Home";

// Instead of importing SignInPage directly,
// import the wrapper component.
import SignInWrapper from "./SignInWrapper";

// Member pages
import CreateMemberProfilePage from "./CreateMemberProfilePage";
import MemberProfilePage from "./MemberProfilePage";

// Bickeree pages
import CreateBickereeProfilePage from "./CreateBickereeProfilePage";
import BickereeDetailPage from "./BickereeDetailPage";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Home />} />

          {/* Only /signin is wrapped by Google provider */}
          <Route path="/signin" element={<SignInWrapper />} />

          {/* Protected for bickerees only */}
          <Route
            path="/create-bickeree"
            element={
              <ProtectedRoute allowedRoles={["bickeree"]}>
                <CreateBickereeProfilePage />
              </ProtectedRoute>
            }
          />

          {/* Protected for members only */}
          <Route
            path="/create-member"
            element={
              <ProtectedRoute allowedRoles={["member"]}>
                <CreateMemberProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bickerees/:netid"
            element={
              <ProtectedRoute allowedRoles={["member"]}>
                <NavBar />
                <BickereeDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/members/:netid"
            element={
              <ProtectedRoute allowedRoles={["member"]}>
                <NavBar />
                <MemberProfilePage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
