// frontend/src/SignInWrapper.js
import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SignInPage from "./SignInPage";

// Replace with your actual client ID from Google
const CLIENT_ID = "1063445378340-9jj733r41dlqb7a0f6m8igtijuababk1.apps.googleusercontent.com";

function SignInWrapper() {
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <SignInPage />
    </GoogleOAuthProvider>
  );
}

export default SignInWrapper;
