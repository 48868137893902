import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./BickereeDetailPage.css";

const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

/**
 * Utility: read the localStorage single-card usage for a given user
 * Returns an object like { crane: true, last_word: false, non_affiliation: false }
 */
function getSingleCardStateForUser(userNetid) {
  if (!userNetid) return {}; // no user yet
  const key = `singleCards_${userNetid}`;
  const stored = localStorage.getItem(key);
  if (stored) {
    try {
      return JSON.parse(stored);
    } catch {
      return {};
    }
  }
  return {};
}

/**
 * Utility: update localStorage to mark a single-use card as "used"
 */
function setSingleCardUsed(userNetid, cardType) {
  if (!userNetid) return;
  const key = `singleCards_${userNetid}`;
  const existing = getSingleCardStateForUser(userNetid);
  existing[cardType] = true;
  localStorage.setItem(key, JSON.stringify(existing));
}

function BickereeDetailPage() {
  const { netid } = useParams();
  const navigate = useNavigate();

  const [bickeree, setBickeree] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Current session user info
  const [sessionUser, setSessionUser] = useState(null);

  // Local list of bickerees (for prev/next)
  const [allBickerees, setAllBickerees] = useState([]);

  // For the modal
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const [cardType, setCardType] = useState("");
  const [cardComment, setCardComment] = useState("");
  const [editingCardId, setEditingCardId] = useState(null);

  // localStorage single-cards usage for the current user
  const [singleCardUsage, setSingleCardUsage] = useState({});

  useEffect(() => {
    // 1) read allBickerees from localStorage (if any)
    const stored = localStorage.getItem("allBickerees");
    if (stored) {
      setAllBickerees(JSON.parse(stored));
    }

    // 2) Fetch session => sets sessionUser
    fetchSession();

    // 3) Fetch the current Bickeree by netid
    fetchBickeree();
  }, [netid]);

  // Once we have sessionUser, load or update the singleCardUsage state
  useEffect(() => {
    if (sessionUser?.netid) {
      setSingleCardUsage(getSingleCardStateForUser(sessionUser.netid));
    }
  }, [sessionUser]);

  async function fetchSession() {
    try {
      const resp = await fetch(`${API_BASE_URL}/check-session`, {
        credentials: "include",
      });
      if (resp.ok) {
        const data = await resp.json();
        setSessionUser(data);
      } else {
        setSessionUser(null);
      }
    } catch (err) {
      console.error("Session fetch error:", err);
      setSessionUser(null);
    }
  }

  async function fetchBickeree() {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/bickerees/${netid}`, {
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setBickeree(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }

  // Early returns
  if (loading) {
    return <div className="bdp-container">Loading Bickeree...</div>;
  }
  if (error) {
    return <div className="bdp-container">Error: {error}</div>;
  }
  if (!bickeree) {
    return <div className="bdp-container">No Bickeree data found.</div>;
  }

  // Prev/Next
  const currentIndex = allBickerees.findIndex((item) => item.netid === bickeree.netid);
  const isFirst = currentIndex <= 0;
  const isLast = currentIndex === allBickerees.length - 1;

  function handlePrev() {
    if (isFirst) return;
    const prevBick = allBickerees[currentIndex - 1];
    if (prevBick) {
      navigate(`/bickerees/${prevBick.netid}`);
    }
  }

  function handleNext() {
    if (isLast) return;
    const nextBick = allBickerees[currentIndex + 1];
    if (nextBick) {
      navigate(`/bickerees/${nextBick.netid}`);
    }
  }

  // separate card types
  const greenCards = bickeree.cards?.filter((c) => c.type === "green") || [];
  const redCards = bickeree.cards?.filter((c) => c.type === "red") || [];
  const craneCard = bickeree.cards?.find((c) => c.type === "crane");
  const lastWordCard = bickeree.cards?.find((c) => c.type === "last_word");
  const nonAffCard = bickeree.cards?.find((c) => c.type === "non_affiliation");

  function userIsAuthor(card) {
    return sessionUser && card.author_netid === sessionUser.netid;
  }

  const hasGreenCard = greenCards.some(userIsAuthor);
  const hasRedCard = redCards.some(userIsAuthor);

  // For single-cards, we also check localStorage usage
  // e.g. if singleCardUsage["crane"] is true => hide the "Add" button
  // because the user has already used that single card somewhere else.
  const hasCraneCard = craneCard && userIsAuthor(craneCard);
  const hasLastWordCard = lastWordCard && userIsAuthor(lastWordCard);
  const hasNonAffCard = nonAffCard && userIsAuthor(nonAffCard);

  // Checking localStorage usage as well
  const craneUsed = singleCardUsage.crane === true;
  const lastWordUsed = singleCardUsage.last_word === true;
  const nonAffUsed = singleCardUsage.non_affiliation === true;

  // handleCreateCard
  function handleCreateCard(newType) {
    setModalMode("create");
    setCardType(newType);
    setCardComment("");
    setEditingCardId(null);
    setShowModal(true);
  }

  function handleEditCard(existingCard) {
    setModalMode("edit");
    setCardType(existingCard.type);
    setCardComment(existingCard.comment);
    setEditingCardId(existingCard.id);
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  async function saveCard() {
    if (!cardComment.trim()) {
      alert("Comment cannot be empty.");
      return;
    }
    try {
      let url = `${API_BASE_URL}/bickerees/${bickeree.netid}/cards`;
      let method = "POST";
      let bodyData = { type: cardType, comment: cardComment };

      if (modalMode === "edit") {
        url += `/${editingCardId}`;
        method = "PUT";
        bodyData = { comment: cardComment };
      }

      const res = await fetch(url, {
        method,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyData),
      });

      if (!res.ok) {
        const errData = await res.json();
        if (res.status === 409) {
          alert(errData.detail || "Conflict: This card type may already exist.");
          return;
        }
        throw new Error(errData.detail || "Failed to save card.");
      }

      // success
      closeModal();
      fetchBickeree();

      // If it's a single-card type & we are the author => store in localStorage
      // so we won't see an Add button again in the future
      if (modalMode === "create") {
        if (["crane", "last_word", "non_affiliation"].includes(cardType) && sessionUser) {
          setSingleCardUsed(sessionUser.netid, cardType);
          // Also refresh state
          const updated = getSingleCardStateForUser(sessionUser.netid);
          setSingleCardUsage(updated);
        }
      }
    } catch (err) {
      console.error("Save card error:", err);
      alert(err.message);
    }
  }

  async function handleDeleteCard() {
    const confirmDelete = window.confirm("Are you sure you want to delete this card?");
    if (!confirmDelete) return;
    try {
      const url = `${API_BASE_URL}/bickerees/${bickeree.netid}/cards/${editingCardId}`;
      const res = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });

      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.detail || "Failed to delete card.");
      }

      closeModal();
      fetchBickeree();

      // If the user is removing a single-card type => remove from localStorage usage
      if (["crane", "last_word", "non_affiliation"].includes(cardType) && sessionUser) {
        const key = `singleCards_${sessionUser.netid}`;
        const usage = getSingleCardStateForUser(sessionUser.netid);
        usage[cardType] = false; // or delete usage[cardType]
        localStorage.setItem(key, JSON.stringify(usage));
        setSingleCardUsage({ ...usage });
      }

    } catch (err) {
      console.error("Delete card error:", err);
      alert(err.message);
    }
  }

  return (
    <div className="bdp-container">
      {/* -- Header with left profile + Green/Red columns -- */}
      <div className="bdp-header">
        {/* Profile Card */}
        <div className="profile-card">
          {bickeree.photo && (
            <img
              className="profile-photo"
              src={`data:image/jpeg;base64,${bickeree.photo}`}
              alt="Bickeree Profile"
            />
          )}
          <h2 className="profile-name">
            {bickeree.first_name} {bickeree.last_name}
          </h2>
          <div className="profile-affiliation">
            {bickeree.affiliation || "No affiliation provided"}
          </div>
        </div>

        {/* Green Cards */}
        <div className="cards-column">
          <div className="cards-column-header">
            <h3 className="cards-column-title">
              Green Cards - {greenCards.length}
            </h3>
            {!hasGreenCard && (
              <button className="add-card-btn" onClick={() => handleCreateCard("green")}>
                Add
              </button>
            )}
          </div>
          <div className="cards-list green-bg">
            {greenCards.length ? (
              greenCards.map((card) => (
                <div key={card.id} className="card-item green-card">
                  <p>{card.comment}</p>
                  <div className="card-meta">
                    {card.author_first_name} {card.author_last_name}
                    {userIsAuthor(card) && (
                      <span
                        className="edit-icon"
                        onClick={() => handleEditCard(card)}
                      >
                        &#9998;
                      </span>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="empty-state">No green cards yet.</div>
            )}
          </div>
        </div>

        {/* Red Cards */}
        <div className="cards-column">
          <div className="cards-column-header">
            <h3 className="cards-column-title">
              Red Cards - {redCards.length}
            </h3>
            {!hasRedCard && (
              <button className="add-card-btn" onClick={() => handleCreateCard("red")}>
                Add
              </button>
            )}
          </div>
          <div className="cards-list red-bg">
            {redCards.length ? (
              redCards.map((card) => (
                <div key={card.id} className="card-item red-card">
                  <p>{card.comment}</p>
                  <div className="card-meta">
                    {card.author_first_name} {card.author_last_name}
                    {userIsAuthor(card) && (
                      <span
                        className="edit-icon"
                        onClick={() => handleEditCard(card)}
                      >
                        &#9998;
                      </span>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="empty-state">No red cards yet.</div>
            )}
          </div>
        </div>
      </div>

      {/* Bottom row: Non-Aff, Crane, Last Word */}
      <div className="bdp-bottom-row">
        {/* Non-Affiliation */}
        <div className="single-card-box">
          <div className="single-card-box-header">
            <h3>Non-Affiliation</h3>
            {/* If localStorage says we used "non_affiliation", hide the button */}
            {!nonAffCard && !singleCardUsage["non_affiliation"] && (
              <button
                className="add-single-btn"
                onClick={() => handleCreateCard("non_affiliation")}
              >
                + Add
              </button>
            )}
            {/* If we do have nonAffCard, show pencil if user is author */}
            {nonAffCard && userIsAuthor(nonAffCard) && (
              <span
                className="edit-icon"
                onClick={() => handleEditCard(nonAffCard)}
              >
                &#9998;
              </span>
            )}
          </div>
          <div className="single-card-content">
            {nonAffCard ? (
              <>
                <p>{nonAffCard.comment}</p>
                <div className="card-meta">
                  {nonAffCard.author_first_name} {nonAffCard.author_last_name}
                </div>
              </>
            ) : (
              <div className="empty-state">No Non-Affiliation card.</div>
            )}
          </div>
        </div>

        {/* Crane Card */}
        <div className="single-card-box">
          <div className="single-card-box-header">
            <h3>Crane Card</h3>
            {/* If localStorage says crane is used, hide add */}
            {!craneCard && !singleCardUsage["crane"] && (
              <button
                className="add-single-btn"
                onClick={() => handleCreateCard("crane")}
              >
                + Add
              </button>
            )}
            {craneCard && userIsAuthor(craneCard) && (
              <span
                className="edit-icon"
                onClick={() => handleEditCard(craneCard)}
              >
                &#9998;
              </span>
            )}
          </div>
          <div className="single-card-content">
            {craneCard ? (
              <>
                <p>{craneCard.comment}</p>
                <div className="card-meta">
                  {craneCard.author_first_name} {craneCard.author_last_name}
                </div>
              </>
            ) : (
              <div className="empty-state">No Crane card.</div>
            )}
          </div>
        </div>

        {/* Last Word */}
        <div className="single-card-box">
          <div className="single-card-box-header">
            <h3>Last Word</h3>
            {!lastWordCard && !singleCardUsage["last_word"] && (
              <button
                className="add-single-btn"
                onClick={() => handleCreateCard("last_word")}
              >
                + Add
              </button>
            )}
            {lastWordCard && userIsAuthor(lastWordCard) && (
              <span
                className="edit-icon"
                onClick={() => handleEditCard(lastWordCard)}
              >
                &#9998;
              </span>
            )}
          </div>
          <div className="single-card-content">
            {lastWordCard ? (
              <>
                <p>{lastWordCard.comment}</p>
                <div className="card-meta">
                  {lastWordCard.author_first_name} {lastWordCard.author_last_name}
                </div>
              </>
            ) : (
              <div className="empty-state">No Last Word card.</div>
            )}
          </div>
        </div>
      </div>

      {/* Prev/Next Buttons */}
      <div style={{ marginTop: "2rem", textAlign: "center" }}>
        <button
          onClick={handlePrev}
          disabled={isFirst}
          className="bick-nav-btn"
          style={{ marginRight: "1rem" }}
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={isLast}
          className="bick-nav-btn"
        >
          Next
        </button>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content new-card-modal">
            <div className="modal-header">
              <h2 className="modal-title">
                {modalMode === "create" ? "New" : "Edit"} Card
              </h2>
            </div>
            <div className="modal-body">
              <label htmlFor="card-comment" className="modal-label">
                Comment
              </label>
              <textarea
                id="card-comment"
                className="modal-textarea"
                rows={5}
                value={cardComment}
                onChange={(e) => setCardComment(e.target.value)}
                placeholder="Type your comment here..."
              />
            </div>
            <div className="modal-footer">
              {modalMode === "edit" && (
                <button
                  className="modal-delete-btn"
                  onClick={handleDeleteCard}
                >
                  Delete
                </button>
              )}
              <button
                className="modal-cancel-btn"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="modal-save-btn"
                onClick={saveCard}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BickereeDetailPage;
